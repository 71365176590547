import { EnvUtil } from '@aperza/util';

type ConfigMember = {
  accountDomain: string;
  staticDomain: string;
  cloudDomain: string;
  idLoginUrl: string;
  idLogoutUrl: string;
  idSignupUrl: string;
  idChangeEmailUrl: string;
};

type ConfigObject = {
  configLocal: ConfigMember;
  configTest: ConfigMember;
  configTest2: ConfigMember;
  configStg: ConfigMember;
  configProd: ConfigMember;
};

const _configObject = {
  configLocal: {
    accountDomain:    '',
    staticDomain:     '',
    cloudDomain:      '',
    idLoginUrl:       '',
    idLogoutUrl:      '',
    idSignupUrl:      '',
    idChangeEmailUrl: '',
  },
  configTest: {
    accountDomain:    '',
    staticDomain:     '',
    cloudDomain:      '',
    idLoginUrl:       '',
    idLogoutUrl:      '',
    idSignupUrl:      '',
    idChangeEmailUrl: '',
  },
  configTest2: {
    accountDomain:    '',
    staticDomain:     '',
    cloudDomain:      '',
    idLoginUrl:       '',
    idLogoutUrl:      '',
    idSignupUrl:      '',
    idChangeEmailUrl: '',
  },
  configStg: {
    accountDomain:    '',
    staticDomain:     '',
    cloudDomain:      '',
    idLoginUrl:       '',
    idLogoutUrl:      '',
    idSignupUrl:      '',
    idChangeEmailUrl: '',
  },
  configProd: {
    accountDomain:    '',
    staticDomain:     '',
    cloudDomain:      '',
    idLoginUrl:       '',
    idLogoutUrl:      '',
    idSignupUrl:      '',
    idChangeEmailUrl: '',
  },
};

export const getConfig = (configObject: ConfigObject = _configObject) => {

  const env: string = EnvUtil.getAppEnv();
  if (env === EnvUtil.ENV_TEST) {

    return configObject.configTest;

  }
  if (env === EnvUtil.ENV_TEST_2) {

    return configObject.configTest2;

  }
  if (env === EnvUtil.ENV_STG) {

    return configObject.configStg;

  }
  if (env === EnvUtil.ENV_PROD) {

    return configObject.configProd;

  }
  return configObject.configLocal;

};
