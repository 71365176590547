import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** java.math.BigInteger */
  BigInteger: number;
  /** Local Date type */
  LocalDate: Date;
  /** Local Date Time type */
  LocalDateTime: Date;
  /** Local Time type */
  LocalTime: any;
  /** Long type */
  Long: number;
  _FieldSet: any;
};






export type AcCompanyContract = {
  __typename?: 'AcCompanyContract';
  /**  Company Contract Id */
  companyContractId: Scalars['String'];
  /**  Company Contract Display Id */
  companyContractDisplayId: Scalars['Int'];
  /**  Cluez Company Id */
  cluezCompanyId: Scalars['BigInteger'];
  /**  Company Publish Flag */
  companyPublish: Scalars['Boolean'];
  /**  Company Logo Url */
  companyLogoUrl?: Maybe<Scalars['String']>;
  /**  Contractor Trade Name Position */
  contractTradeNamePosition?: Maybe<TradeNamePosition>;
  /**  Company Contract Company Name */
  contractCompanyName: Scalars['String'];
  /**  Company Contract Company Phonetic Name */
  contractCompanyPhoneticName?: Maybe<Scalars['String']>;
  /**  Company Contract Full Name */
  contractCompanyNameFull?: Maybe<Scalars['String']>;
  /**  Company Contract Company Department Name */
  contractDepartmentName?: Maybe<Scalars['String']>;
  /**  Company Contract Company Department Phonetic Name */
  contractDepartmentPhoneticName?: Maybe<Scalars['String']>;
  /**  Contractor Name  */
  contractName?: Maybe<Scalars['String']>;
  /**  Contractor Email */
  contractEmail?: Maybe<Scalars['String']>;
  /**  Contractor Country Code */
  contractCountryCode?: Maybe<Scalars['String']>;
  /**  Contractor Country Name */
  contractCountryName?: Maybe<Scalars['String']>;
  /**  Contractor Zip Code */
  contractZipcode?: Maybe<Scalars['String']>;
  /**  Contractor Subdivision Code  */
  contractSubdivisionCode?: Maybe<Scalars['String']>;
  /**  Contractor Subdivision Name */
  contractSubdivisionName?: Maybe<Scalars['String']>;
  /**  Contractor City Name */
  contractCityName?: Maybe<Scalars['String']>;
  /**  Contractor Address */
  contractAddress?: Maybe<Scalars['String']>;
  /**  Contractor Building */
  contractBuilding?: Maybe<Scalars['String']>;
  /**  Contractor Tel */
  contractTel?: Maybe<Scalars['String']>;
  /**  Contractor Fax */
  contractFax?: Maybe<Scalars['String']>;
  /**  Contractor Company Category Id */
  contractCompanyCategoryId?: Maybe<Scalars['Int']>;
  /**  Contractor Employees Description */
  contractEmployeesDescription?: Maybe<Scalars['Int']>;
  /**  Contractor Sales Description */
  contractSalesDescription?: Maybe<Scalars['Int']>;
  /**  User Authority Level of Aperza Cloud User */
  userAuthorityLevel?: Maybe<UserAuthorityLevel>;
  /**  Registration Agency Flag of Aperza Cloud User */
  registrationAgencyFlag?: Maybe<RegistrationAgencyFlag>;
  /**  Registration Agency Authority Type of Aperza Cloud User */
  registrationAgencyAuthorityType?: Maybe<RegistrationAgencyAuthorityType>;
  /**   Use Catalog Limited Publish Flag of Company Contract */
  useCatalogLimitedPublishFlag: LimitedFunctionFlag;
  /**  Use Application Permission Typeof Company Contract */
  useApplicationPermissionType: LimitedFunctionFlag;
};

export type AcCompanyContractFax = {
  __typename?: 'AcCompanyContractFax';
  /**  company contract fax id */
  id: Scalars['BigInteger'];
  /**  company contract fax name */
  name: Scalars['String'];
  /**  fax number */
  fax: Scalars['String'];
  /**  current selected fax */
  selected: Scalars['Boolean'];
};

export type AcUser = {
  __typename?: 'AcUser';
  /**  user id of AperzaId */
  userId?: Maybe<Scalars['BigInteger']>;
  /**  display name of AperzaId */
  displayName?: Maybe<Scalars['String']>;
  /**  full name of AperzaId */
  fullName?: Maybe<Scalars['String']>;
  /**  photo url of AperzaId */
  photoUrl?: Maybe<Scalars['String']>;
  /**  Current Company Contract */
  currentCompanyContract?: Maybe<AcCompanyContract>;
  /**  current Authorities of Aperza Cloud User */
  currentAuthorities: Array<AperzaCloudUserAuthority>;
  /**  other Company Contracts */
  otherCompanyContracts: Array<AcCompanyContract>;
  /**  Company Contract Faxes of Aperza Cloud User */
  companyContractFaxes: Array<AcCompanyContractFax>;
  /**  with menus */
  menus?: Maybe<Array<Maybe<Menu>>>;
};


export type AcUserMenusArgs = {
  menuType: MenuType;
};

export type AperzaCloudUserAuthority = {
  __typename?: 'AperzaCloudUserAuthority';
  /**  Authority Role Name */
  roleName: Scalars['String'];
  userAuthorityLevel: Scalars['Int'];
  userAvailabilityFlag: Scalars['Int'];
  userReferabilityFlag: Scalars['Int'];
  userEditabilityFlag: Scalars['Int'];
  userDataCreateFlag: Scalars['Int'];
  userDataModifyFlag: Scalars['Int'];
  userDataRemoveFlag: Scalars['Int'];
};


export type Company = {
  __typename?: 'Company';
  companyId: Scalars['String'];
  companyName: Scalars['String'];
  companyLogoUrl?: Maybe<Scalars['String']>;
  companyNameFull?: Maybe<Scalars['String']>;
};

export type CompanyContract = {
  __typename?: 'CompanyContract';
  companyContractId: Scalars['String'];
  contractCompanyName?: Maybe<Scalars['String']>;
  contractDepartmentName?: Maybe<Scalars['String']>;
  contractName?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
};

export type ContentLibraryDetail = {
  __typename?: 'ContentLibraryDetail';
  /**  id of content library */
  contentLibraryId: Scalars['BigInteger'];
  /**  type name of content library */
  contentLibraryTypeName: Scalars['String'];
  /**  title of content library */
  contentLibraryTitle: Scalars['String'];
  /** sub title of content library */
  contentLibrarySubTitle?: Maybe<Scalars['String']>;
  /**  description of content library */
  contentLibraryDescription?: Maybe<Scalars['String']>;
  /**  play time of content libray */
  contentLibraryPlayTime: Scalars['String'];
  /**  thumbnail image url of content library */
  contentLibraryThumbnailImageUrl?: Maybe<Scalars['String']>;
  /**  video url of content library */
  contentLibraryVideoUrl?: Maybe<Scalars['String']>;
  /**  publish start datetime of content library */
  publishStartDatetime: Scalars['LocalDateTime'];
  /**  publish end datetime of content library */
  publishEndDatetime: Scalars['LocalDateTime'];
  /**  registered datetime on content library */
  registeredDatetime: Scalars['LocalDateTime'];
  /**  video page created  */
  contentLibraryCreated: Scalars['Boolean'];
  /**  companyContract */
  companyContract: CompanyContract;
  /**  ContentLibrary ProductCategory */
  contentLibraryProductCategories: Array<Maybe<ContentLibraryProductCategory>>;
};


export type ContentLibraryDetailCompanyContractArgs = {
  withCompanysNoLogo?: Maybe<Scalars['Boolean']>;
};


export type ContentLibraryDetailContentLibraryProductCategoriesArgs = {
  master?: Maybe<Scalars['Boolean']>;
  firstOnly?: Maybe<Scalars['Boolean']>;
};

export type ContentLibraryHeadline = {
  __typename?: 'ContentLibraryHeadline';
  /**  id of content library */
  contentLibraryId: Scalars['BigInteger'];
  /**  type name of content library */
  contentLibraryTypeName: Scalars['String'];
  /**  title of content library */
  contentLibraryTitle: Scalars['String'];
  /** sub title of content library */
  contentLibrarySubTitle?: Maybe<Scalars['String']>;
  /**  description of content library */
  contentLibraryDescription?: Maybe<Scalars['String']>;
  /**  play time of content libray */
  contentLibraryPlayTime: Scalars['String'];
  /**  thumbnail image url of content library */
  contentLibraryThumbnailImageUrl?: Maybe<Scalars['String']>;
  /**  publish start datetime of content library */
  publishStartDatetime: Scalars['LocalDateTime'];
  /**  publish end datetime of content library */
  publishEndDatetime: Scalars['LocalDateTime'];
  /**  registered datetime on content library */
  registeredDatetime: Scalars['LocalDateTime'];
  /**  video page created  */
  contentLibraryCreated: Scalars['Boolean'];
  /**  companyContract */
  companyContract: CompanyContract;
  /**  ContentLibrary ProductCategory */
  contentLibraryProductCategories: Array<Maybe<ContentLibraryProductCategory>>;
};


export type ContentLibraryHeadlineCompanyContractArgs = {
  withCompanysNoLogo?: Maybe<Scalars['Boolean']>;
};


export type ContentLibraryHeadlineContentLibraryProductCategoriesArgs = {
  master?: Maybe<Scalars['Boolean']>;
  firstOnly?: Maybe<Scalars['Boolean']>;
};

export type ContentLibraryHeadlineCompany = {
  __typename?: 'ContentLibraryHeadlineCompany';
  /**  id using companyContractId */
  id?: Maybe<Scalars['ID']>;
  /**  companyContractId */
  companyContractId: Scalars['String'];
  /**  companyContractDisplayId */
  companyContractDisplayId: Scalars['BigInteger'];
  /**  companyName */
  companyName: Scalars['String'];
  /**  contents count of company */
  contentsCount: Scalars['Int'];
};

export type ContentLibraryHeadlines = {
  __typename?: 'ContentLibraryHeadlines';
  /**  ContentLibraryHeadline Content */
  content: Array<Maybe<ContentLibraryHeadline>>;
  /**  page */
  page: Scalars['Int'];
  /**  size */
  size: Scalars['Int'];
  /**  total elements */
  totalElements: Scalars['Long'];
  /**  total pages */
  totalPages: Scalars['Int'];
  /**  list of company */
  contentLibraryHeadlineCompanies?: Maybe<Array<ContentLibraryHeadlineCompany>>;
};

/**  ContentLibrary Product Category */
export type ContentLibraryProductCategory = {
  __typename?: 'ContentLibraryProductCategory';
  productCategoryId: Scalars['BigInteger'];
  productCategoryName: Scalars['String'];
  productCategoryBreadCrumbs: Array<Maybe<ProductCategoryBreadCrumb>>;
  displayOrder: Scalars['Int'];
  productCategoryLevel1: Array<Maybe<ProductCategory>>;
  productCategoryLevel2?: Maybe<Array<Maybe<ProductCategory>>>;
  productCategoryLevel3?: Maybe<Array<Maybe<ProductCategory>>>;
  productCategoryLevel4?: Maybe<Array<Maybe<ProductCategory>>>;
};

export type CreatedVideoPage = {
  __typename?: 'CreatedVideoPage';
  videoPageId: Scalars['BigInteger'];
  videoPageDisplayId: Scalars['BigInteger'];
  videoPageLangId: Scalars['String'];
};

export enum ErrorDetail {
  /**
   * Unknown error.
   *
   * This error should only be returned when no other error detail applies.
   * If a client sees an unknown errorDetail, it will be interpreted as UNKNOWN.
   *
   * HTTP Mapping: 500 Internal Server Error
   */
  Unknown = 'UNKNOWN',
  /**
   * The requested field is not found in the schema.
   *
   * This differs from `NOT_FOUND` in that `NOT_FOUND` should be used when a
   * query is valid, but is unable to return a result (if, for example, a
   * specific video id doesn't exist). `FIELD_NOT_FOUND` is intended to be
   * returned by the server to signify that the requested field is not known to exist.
   * This may be returned in lieu of failing the entire query.
   * See also `PERMISSION_DENIED` for cases where the
   * requested field is invalid only for the given user or class of users.
   *
   * HTTP Mapping: 404 Not Found
   * Error Type: BAD_REQUEST
   */
  FieldNotFound = 'FIELD_NOT_FOUND',
  /**
   * The provided cursor is not valid.
   *
   * The most common usage for this error is when a client is paginating
   * through a list that uses stateful cursors. In that case, the provided
   * cursor may be expired.
   *
   * HTTP Mapping: 404 Not Found
   * Error Type: NOT_FOUND
   */
  InvalidCursor = 'INVALID_CURSOR',
  /**
   * The operation is not implemented or is not currently supported/enabled.
   *
   * HTTP Mapping: 501 Not Implemented
   * Error Type: BAD_REQUEST
   */
  Unimplemented = 'UNIMPLEMENTED',
  /**
   * The client specified an invalid argument.
   *
   * Note that this differs from `FAILED_PRECONDITION`.
   * `INVALID_ARGUMENT` indicates arguments that are problematic
   * regardless of the state of the system (e.g., a malformed file name).
   *
   * HTTP Mapping: 400 Bad Request
   * Error Type: BAD_REQUEST
   */
  InvalidArgument = 'INVALID_ARGUMENT',
  /**
   * The deadline expired before the operation could complete.
   *
   * For operations that change the state of the system, this error
   * may be returned even if the operation has completed successfully.
   * For example, a successful response from a server could have been
   * delayed long enough for the deadline to expire.
   *
   * HTTP Mapping: 504 Gateway Timeout
   * Error Type: UNAVAILABLE
   */
  DeadlineExceeded = 'DEADLINE_EXCEEDED',
  /**
   * Service Error.
   *
   * There is a problem with an upstream service.
   *
   * This may be returned if a gateway receives an unknown error from a service
   * or if a service is unreachable.
   * If a request times out which waiting on a response from a service,
   * `DEADLINE_EXCEEDED` may be returned instead.
   * If a service returns a more specific error Type, the specific error Type may
   * be returned instead.
   *
   * HTTP Mapping: 502 Bad Gateway
   * Error Type: UNAVAILABLE
   */
  ServiceError = 'SERVICE_ERROR',
  /**
   * Request throttled based on server CPU limits
   *
   * HTTP Mapping: 503 Unavailable.
   * Error Type: UNAVAILABLE
   */
  ThrottledCpu = 'THROTTLED_CPU',
  /**
   * Request throttled based on server concurrency limits.
   *
   * HTTP Mapping: 503 Unavailable
   * Error Type: UNAVAILABLE
   */
  ThrottledConcurrency = 'THROTTLED_CONCURRENCY',
  /**
   * The server detected that the client is exhibiting a behavior that
   * might be generating excessive load.
   *
   * HTTP Mapping: 429 Too Many Requests or 420 Enhance Your Calm
   * Error Type: UNAVAILABLE
   */
  EnhanceYourCalm = 'ENHANCE_YOUR_CALM',
  /**
   * Request failed due to network errors.
   *
   * HTTP Mapping: 503 Unavailable
   * Error Type: UNAVAILABLE
   */
  TcpFailure = 'TCP_FAILURE',
  /**
   * Unable to perform operation because a required resource is missing.
   *
   * Example: Client is attempting to refresh a list, but the specified
   * list is expired. This requires an action by the client to get a new list.
   *
   * If the user is simply trying GET a resource that is not found,
   * use the NOT_FOUND error type. FAILED_PRECONDITION.MISSING_RESOURCE
   * is to be used particularly when the user is performing an operation
   * that requires a particular resource to exist.
   *
   * HTTP Mapping: 400 Bad Request or 500 Internal Server Error
   * Error Type: FAILED_PRECONDITION
   */
  MissingResource = 'MISSING_RESOURCE'
}

export enum ErrorType {
  /**
   * Unknown error.
   *
   * For example, this error may be returned when
   * an error code received from another address space belongs to
   * an error space that is not known in this address space.  Also
   * errors raised by APIs that do not return enough error information
   * may be converted to this error.
   *
   * If a client sees an unknown errorType, it will be interpreted as UNKNOWN.
   * Unknown errors MUST NOT trigger any special behavior. These MAY be treated
   * by an implementation as being equivalent to INTERNAL.
   *
   * When possible, a more specific error should be provided.
   *
   * HTTP Mapping: 520 Unknown Error
   */
  Unknown = 'UNKNOWN',
  /**
   * Internal error.
   *
   * An unexpected internal error was encountered. This means that some
   * invariants expected by the underlying system have been broken.
   * This error code is reserved for serious errors.
   *
   * HTTP Mapping: 500 Internal Server Error
   */
  Internal = 'INTERNAL',
  /**
   * The requested entity was not found.
   *
   * This could apply to a resource that has never existed (e.g. bad resource id),
   * or a resource that no longer exists (e.g. cache expired.)
   *
   * Note to server developers: if a request is denied for an entire class
   * of users, such as gradual feature rollout or undocumented allowlist,
   * `NOT_FOUND` may be used. If a request is denied for some users within
   * a class of users, such as user-based access control, `PERMISSION_DENIED`
   * must be used.
   *
   * HTTP Mapping: 404 Not Found
   */
  NotFound = 'NOT_FOUND',
  /**
   * The request does not have valid authentication credentials.
   *
   * This is intended to be returned only for routes that require
   * authentication.
   *
   * HTTP Mapping: 401 Unauthorized
   */
  Unauthenticated = 'UNAUTHENTICATED',
  /**
   * The caller does not have permission to execute the specified
   * operation.
   *
   * `PERMISSION_DENIED` must not be used for rejections
   * caused by exhausting some resource or quota.
   * `PERMISSION_DENIED` must not be used if the caller
   * cannot be identified (use `UNAUTHENTICATED`
   * instead for those errors).
   *
   * This error Type does not imply the
   * request is valid or the requested entity exists or satisfies
   * other pre-conditions.
   *
   * HTTP Mapping: 403 Forbidden
   */
  PermissionDenied = 'PERMISSION_DENIED',
  /**
   * Bad Request.
   *
   * There is a problem with the request.
   * Retrying the same request is not likely to succeed.
   * An example would be a query or argument that cannot be deserialized.
   *
   * HTTP Mapping: 400 Bad Request
   */
  BadRequest = 'BAD_REQUEST',
  /**
   * Currently Unavailable.
   *
   * The service is currently unavailable.  This is most likely a
   * transient condition, which can be corrected by retrying with
   * a backoff.
   *
   * HTTP Mapping: 503 Unavailable
   */
  Unavailable = 'UNAVAILABLE',
  /**
   * The operation was rejected because the system is not in a state
   * required for the operation's execution.  For example, the directory
   * to be deleted is non-empty, an rmdir operation is applied to
   * a non-directory, etc.
   *
   * Service implementers can use the following guidelines to decide
   * between `FAILED_PRECONDITION` and `UNAVAILABLE`:
   *
   * - Use `UNAVAILABLE` if the client can retry just the failing call.
   * - Use `FAILED_PRECONDITION` if the client should not retry until
   * the system state has been explicitly fixed.  E.g., if an "rmdir"
   *      fails because the directory is non-empty, `FAILED_PRECONDITION`
   * should be returned since the client should not retry unless
   * the files are deleted from the directory.
   *
   * HTTP Mapping: 400 Bad Request or 500 Internal Server Error
   */
  FailedPrecondition = 'FAILED_PRECONDITION'
}

export enum GrantedAuthorityType {
  HomeFunction = 'HOME_FUNCTION',
  NoticeFunction = 'NOTICE_FUNCTION',
  SettingFunction = 'SETTING_FUNCTION',
  SettingNotificationFunction = 'SETTING_NOTIFICATION_FUNCTION',
  UserFunction = 'USER_FUNCTION',
  ContractFunction = 'CONTRACT_FUNCTION',
  PublishFunction = 'PUBLISH_FUNCTION',
  CompanyFunction = 'COMPANY_FUNCTION',
  BrandFunction = 'BRAND_FUNCTION',
  ContactFunction = 'CONTACT_FUNCTION',
  EcContactFunction = 'EC_CONTACT_FUNCTION',
  MessageFunction = 'MESSAGE_FUNCTION',
  AnalyticsFunction = 'ANALYTICS_FUNCTION',
  ContentsTopFunction = 'CONTENTS_TOP_FUNCTION',
  ContentsProductFunction = 'CONTENTS_PRODUCT_FUNCTION',
  ContentsDocumentFunction = 'CONTENTS_DOCUMENT_FUNCTION',
  ContentsMultimediaFunction = 'CONTENTS_MULTIMEDIA_FUNCTION',
  ContentsNewsFunction = 'CONTENTS_NEWS_FUNCTION',
  CustompageFunction = 'CUSTOMPAGE_FUNCTION',
  ContentsSurveyFunction = 'CONTENTS_SURVEY_FUNCTION',
  ContentsVideoFunction = 'CONTENTS_VIDEO_FUNCTION',
  ContentsFormFunction = 'CONTENTS_FORM_FUNCTION',
  ContentsStorageFunction = 'CONTENTS_STORAGE_FUNCTION',
  ContentsLibraryFunction = 'CONTENTS_LIBRARY_FUNCTION',
  ExhibitionOfflineFunction = 'EXHIBITION_OFFLINE_FUNCTION',
  MailFunction = 'MAIL_FUNCTION',
  InvitationFunction = 'INVITATION_FUNCTION',
  CustomerListFunction = 'CUSTOMER_LIST_FUNCTION',
  CustomerLeadFunction = 'CUSTOMER_LEAD_FUNCTION',
  PartnerTopFunction = 'PARTNER_TOP_FUNCTION',
  PartnerContentsFunction = 'PARTNER_CONTENTS_FUNCTION',
  PartnerApplyFunction = 'PARTNER_APPLY_FUNCTION',
  SalesTopFunction = 'SALES_TOP_FUNCTION',
  SalesOffersFunction = 'SALES_OFFERS_FUNCTION',
  SalesItemsFunction = 'SALES_ITEMS_FUNCTION',
  SalesOrdersFunction = 'SALES_ORDERS_FUNCTION',
  SalesShopFunction = 'SALES_SHOP_FUNCTION',
  SalesShippingFunction = 'SALES_SHIPPING_FUNCTION',
  PurchaseTopFunction = 'PURCHASE_TOP_FUNCTION',
  PurchaseOrdersFunction = 'PURCHASE_ORDERS_FUNCTION',
  PurchaseShippingFunction = 'PURCHASE_SHIPPING_FUNCTION',
  PurchasePaymentFunction = 'PURCHASE_PAYMENT_FUNCTION',
  CsvUploadFunction = 'CSV_UPLOAD_FUNCTION',
  CsvDownloadFunction = 'CSV_DOWNLOAD_FUNCTION',
  NotYetCompanyContract = 'NOT_YET_COMPANY_CONTRACT',
  BusinessCardListFunction = 'BUSINESS_CARD_LIST_FUNCTION',
  FaxFunction = 'FAX_FUNCTION',
  OcrAnalysisFunction = 'OCR_ANALYSIS_FUNCTION',
  ExhibitionListFunction = 'EXHIBITION_LIST_FUNCTION',
  BuyerDataIncompleteMember = 'BUYER_DATA_INCOMPLETE_MEMBER',
  BuyerUnappliedMember = 'BUYER_UNAPPLIED_MEMBER',
  BuyerUnapprovalMember = 'BUYER_UNAPPROVAL_MEMBER',
  BuyerApprovalMember = 'BUYER_APPROVAL_MEMBER',
  BuyerSuspendingMember = 'BUYER_SUSPENDING_MEMBER',
  BuyerRejectionMember = 'BUYER_REJECTION_MEMBER',
  ExternalFunction = 'EXTERNAL_FUNCTION'
}

export enum LimitedFunctionFlag {
  NotPermit = 'NOT_PERMIT',
  Permit = 'PERMIT'
}





export type Menu = {
  __typename?: 'Menu';
  /**  menu name */
  name: Scalars['String'];
  /**  menu icon */
  icon: Scalars['String'];
  /**  menu path */
  path?: Maybe<Scalars['String']>;
  /**  menu external link flag */
  externalLink: Scalars['Boolean'];
  /**  menu external link url */
  externalLinkUrl?: Maybe<Scalars['String']>;
  /**  menu type */
  menuType?: Maybe<MenuType>;
  /**  function roles */
  functionRoles?: Maybe<Array<GrantedAuthorityType>>;
  /**  menu authorized */
  authorized: Scalars['Boolean'];
  /**  current menu flag */
  current: Scalars['Boolean'];
  /**  enable menu */
  enable: Scalars['Boolean'];
  /**  edit authority */
  editAuthority: Scalars['Boolean'];
  /**  active menu flag */
  active: Scalars['Boolean'];
  /**  menu url */
  url?: Maybe<Scalars['String']>;
  /**  link target */
  target: Scalars['String'];
  /**  has childs menu flag */
  hasChild: Scalars['Boolean'];
  /**  child menus */
  childMenus?: Maybe<Array<Menu>>;
};

export enum MenuType {
  Home = 'HOME',
  Notice = 'NOTICE',
  SettingTop = 'SETTING_TOP',
  SettingUser = 'SETTING_USER',
  SettingContract = 'SETTING_CONTRACT',
  SettingPublish = 'SETTING_PUBLISH',
  SettingCompany = 'SETTING_COMPANY',
  SettingBrand = 'SETTING_BRAND',
  SettingContact = 'SETTING_CONTACT',
  SettingEcContact = 'SETTING_EC_CONTACT',
  SettingNotification = 'SETTING_NOTIFICATION',
  Message = 'MESSAGE',
  Analytics = 'ANALYTICS',
  ContentsTop = 'CONTENTS_TOP',
  ContentsProduct = 'CONTENTS_PRODUCT',
  ContentsDocument = 'CONTENTS_DOCUMENT',
  ContentsMultimedia = 'CONTENTS_MULTIMEDIA',
  Custompage = 'CUSTOMPAGE',
  ContentsVideo = 'CONTENTS_VIDEO',
  ContentsForm = 'CONTENTS_FORM',
  ContentsStorage = 'CONTENTS_STORAGE',
  ContentsLibrary = 'CONTENTS_LIBRARY',
  News = 'NEWS',
  Mail = 'MAIL',
  CustomerTop = 'CUSTOMER_TOP',
  CustomerList = 'CUSTOMER_LIST',
  CustomerListGroupList = 'CUSTOMER_LIST_GROUP_LIST',
  CustomerListUpdateList = 'CUSTOMER_LIST_UPDATE_LIST',
  CompanyListUpdateList = 'COMPANY_LIST_UPDATE_LIST',
  OfficeListUpdateList = 'OFFICE_LIST_UPDATE_LIST',
  CustomerLead = 'CUSTOMER_LEAD',
  BusinessCardList = 'BUSINESS_CARD_LIST',
  ExhibitionList = 'EXHIBITION_LIST',
  ExhibitionOffline = 'EXHIBITION_OFFLINE',
  PartnerTop = 'PARTNER_TOP',
  PartnerContents = 'PARTNER_CONTENTS',
  PartnerApply = 'PARTNER_APPLY',
  SalesTop = 'SALES_TOP',
  SalesOffers = 'SALES_OFFERS',
  SalesItems = 'SALES_ITEMS',
  SalesDocuments = 'SALES_DOCUMENTS',
  SalesOrders = 'SALES_ORDERS',
  SalesShop = 'SALES_SHOP',
  SalesShipping = 'SALES_SHIPPING',
  PurchaseTop = 'PURCHASE_TOP',
  PurchaseOrders = 'PURCHASE_ORDERS',
  PurchaseShipping = 'PURCHASE_SHIPPING',
  PurchasePayment = 'PURCHASE_PAYMENT',
  CsvHistory = 'CSV_HISTORY',
  CsvUpload = 'CSV_UPLOAD',
  CsvDownload = 'CSV_DOWNLOAD',
  Fax = 'FAX',
  FaxDocuments = 'FAX_DOCUMENTS',
  FaxContacts = 'FAX_CONTACTS',
  OcrAnalysisPattern = 'OCR_ANALYSIS_PATTERN',
  ExternalMenu = 'EXTERNAL_MENU'
}

export type Mutation = {
  __typename?: 'Mutation';
  /**  change company contract */
  changeContract: Scalars['String'];
  /**  change company contract fax */
  changeFax: Scalars['BigInteger'];
  /**  create Video Page via Contents Library */
  createVideoPage?: Maybe<CreatedVideoPage>;
};


export type MutationChangeContractArgs = {
  companyContractId: Scalars['String'];
};


export type MutationChangeFaxArgs = {
  faxId: Scalars['BigInteger'];
};


export type MutationCreateVideoPageArgs = {
  contentLibraryId: Scalars['BigInteger'];
};

/**  Product Category */
export type ProductCategory = {
  __typename?: 'ProductCategory';
  /**  product category id */
  productCategoryId: Scalars['BigInteger'];
  /**  product category name */
  productCategoryName: Scalars['String'];
  /**  display order */
  displayOrder: Scalars['Int'];
};

export type ProductCategoryBreadCrumb = {
  __typename?: 'ProductCategoryBreadCrumb';
  productCategoryId: Scalars['BigInteger'];
  productCategoryName: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  /**  ping(the number of milliseconds since the epoch of 1970-01-01T00:00:00Z, UTC) */
  ping: Scalars['Long'];
  menus?: Maybe<Array<Maybe<Menu>>>;
  user: AcUser;
  contentLibraryHeadlines: ContentLibraryHeadlines;
  contentLibraryDetail?: Maybe<ContentLibraryDetail>;
  /**  Listing ProductCategory First Category */
  productCategoryLevel1: Array<Maybe<ProductCategory>>;
  /**  Listing ProductCategory Second Category */
  productCategoryLevel2?: Maybe<Array<Maybe<ProductCategory>>>;
  /**  Listing ProductCategory Third Category */
  productCategoryLevel3?: Maybe<Array<Maybe<ProductCategory>>>;
  /**  Listing ProductCategory Four Category */
  productCategoryLevel4?: Maybe<Array<Maybe<ProductCategory>>>;
  _service?: Maybe<_Service>;
};


export type QueryMenusArgs = {
  menuType: MenuType;
};


export type QueryContentLibraryHeadlinesArgs = {
  page: Scalars['Int'];
  size: Scalars['Int'];
  keyword?: Maybe<Scalars['String']>;
};


export type QueryContentLibraryDetailArgs = {
  contentLibraryId: Scalars['BigInteger'];
};


export type QueryProductCategoryLevel2Args = {
  parentProductCategoryId: Scalars['BigInteger'];
};


export type QueryProductCategoryLevel3Args = {
  parentProductCategoryId: Scalars['BigInteger'];
};


export type QueryProductCategoryLevel4Args = {
  parentProductCategoryId: Scalars['BigInteger'];
};

export enum RegistrationAgencyAuthorityType {
  Lv1 = 'LV1',
  Lv2 = 'LV2',
  Lv3 = 'LV3',
  Lv4 = 'LV4',
  Lv5 = 'LV5'
}

export enum RegistrationAgencyFlag {
  Normal = 'NORMAL',
  Agency = 'AGENCY'
}

export enum TradeNamePosition {
  Prefix = 'PREFIX',
  Suffix = 'SUFFIX'
}

export enum UserAuthorityLevel {
  /**  参照権限 */
  Reference = 'REFERENCE',
  /**  スタッフ */
  Staff = 'STAFF',
  /**  営業 */
  Sales = 'SALES',
  /**  リーダー */
  Leader = 'LEADER',
  /**  マネージャー */
  Manager = 'MANAGER',
  /**  管理者 */
  Administrator = 'ADMINISTRATOR',
  /**  コンテンツ管理者 */
  ContentsAdministrator = 'CONTENTS_ADMINISTRATOR',
  /**  登録代行 */
  RegistrationAgency = 'REGISTRATION_AGENCY'
}

/**  Enum */
export enum UserType {
  Guest = 'GUEST',
  Member = 'MEMBER'
}

/**  Response of ValidateProductCategory */
export type ValidateProductCategory = {
  __typename?: 'ValidateProductCategory';
  validate: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};


export type _Service = {
  __typename?: '_Service';
  sdl: Scalars['String'];
};

export type ChangeContractMutationVariables = Exact<{
  companyContractId: Scalars['String'];
}>;


export type ChangeContractMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'changeContract'>
);

export type ChangeFaxMutationVariables = Exact<{
  faxId: Scalars['BigInteger'];
}>;


export type ChangeFaxMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'changeFax'>
);

export type GetMenusQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMenusQuery = (
  { __typename?: 'Query' }
  & { menus?: Maybe<Array<Maybe<(
    { __typename?: 'Menu' }
    & Pick<Menu, 'name' | 'icon' | 'path' | 'externalLink' | 'externalLinkUrl' | 'menuType' | 'functionRoles' | 'authorized' | 'current' | 'enable' | 'editAuthority' | 'active' | 'url' | 'target' | 'hasChild'>
    & { childMenus?: Maybe<Array<(
      { __typename?: 'Menu' }
      & Pick<Menu, 'name' | 'icon' | 'path' | 'externalLink' | 'externalLinkUrl' | 'menuType' | 'functionRoles' | 'authorized' | 'current' | 'enable' | 'editAuthority' | 'active' | 'url' | 'target' | 'hasChild'>
      & { childMenus?: Maybe<Array<(
        { __typename?: 'Menu' }
        & Pick<Menu, 'name' | 'icon' | 'path' | 'externalLink' | 'externalLinkUrl' | 'menuType' | 'functionRoles' | 'authorized' | 'current' | 'enable' | 'editAuthority' | 'active' | 'url' | 'target' | 'hasChild'>
      )>> }
    )>> }
  )>>> }
);

export type GetUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'AcUser' }
    & Pick<AcUser, 'userId' | 'displayName' | 'fullName' | 'photoUrl'>
    & { currentCompanyContract?: Maybe<(
      { __typename?: 'AcCompanyContract' }
      & Pick<AcCompanyContract, 'companyContractId' | 'companyContractDisplayId' | 'cluezCompanyId' | 'companyPublish' | 'companyLogoUrl' | 'contractTradeNamePosition' | 'contractCompanyName' | 'contractCompanyPhoneticName' | 'contractCompanyNameFull' | 'contractDepartmentName' | 'contractDepartmentPhoneticName' | 'contractName' | 'contractEmail' | 'contractCountryCode' | 'contractCountryName' | 'contractZipcode' | 'contractSubdivisionCode' | 'contractSubdivisionName' | 'contractCityName' | 'contractAddress' | 'contractBuilding' | 'contractTel' | 'contractFax' | 'contractCompanyCategoryId' | 'contractEmployeesDescription' | 'contractSalesDescription' | 'userAuthorityLevel' | 'registrationAgencyFlag' | 'registrationAgencyAuthorityType' | 'useCatalogLimitedPublishFlag' | 'useApplicationPermissionType'>
    )>, currentAuthorities: Array<(
      { __typename?: 'AperzaCloudUserAuthority' }
      & Pick<AperzaCloudUserAuthority, 'roleName' | 'userAuthorityLevel' | 'userAvailabilityFlag' | 'userReferabilityFlag' | 'userEditabilityFlag' | 'userDataCreateFlag' | 'userDataModifyFlag' | 'userDataRemoveFlag'>
    )>, otherCompanyContracts: Array<(
      { __typename?: 'AcCompanyContract' }
      & Pick<AcCompanyContract, 'companyContractId' | 'companyContractDisplayId' | 'cluezCompanyId' | 'companyPublish' | 'companyLogoUrl' | 'contractTradeNamePosition' | 'contractCompanyName' | 'contractCompanyPhoneticName' | 'contractCompanyNameFull' | 'contractDepartmentName' | 'contractDepartmentPhoneticName' | 'contractName' | 'contractEmail' | 'contractCountryCode' | 'contractCountryName' | 'contractZipcode' | 'contractSubdivisionCode' | 'contractSubdivisionName' | 'contractCityName' | 'contractAddress' | 'contractBuilding' | 'contractTel' | 'contractFax' | 'contractCompanyCategoryId' | 'contractEmployeesDescription' | 'contractSalesDescription' | 'userAuthorityLevel' | 'registrationAgencyFlag' | 'registrationAgencyAuthorityType' | 'useCatalogLimitedPublishFlag' | 'useApplicationPermissionType'>
    )>, companyContractFaxes: Array<(
      { __typename?: 'AcCompanyContractFax' }
      & Pick<AcCompanyContractFax, 'id' | 'name' | 'fax' | 'selected'>
    )>, menus?: Maybe<Array<Maybe<(
      { __typename?: 'Menu' }
      & Pick<Menu, 'name' | 'icon' | 'path' | 'externalLink' | 'externalLinkUrl' | 'menuType' | 'functionRoles' | 'authorized' | 'current' | 'enable' | 'editAuthority' | 'active' | 'url' | 'target' | 'hasChild'>
      & { childMenus?: Maybe<Array<(
        { __typename?: 'Menu' }
        & Pick<Menu, 'name' | 'icon' | 'path' | 'externalLink' | 'externalLinkUrl' | 'menuType' | 'functionRoles' | 'authorized' | 'current' | 'enable' | 'editAuthority' | 'active' | 'url' | 'target' | 'hasChild'>
        & { childMenus?: Maybe<Array<(
          { __typename?: 'Menu' }
          & Pick<Menu, 'name' | 'icon' | 'path' | 'externalLink' | 'externalLinkUrl' | 'menuType' | 'functionRoles' | 'authorized' | 'current' | 'enable' | 'editAuthority' | 'active' | 'url' | 'target' | 'hasChild'>
        )>> }
      )>> }
    )>>> }
  ) }
);


export const ChangeContractDocument = gql`
    mutation changeContract($companyContractId: String!) {
  changeContract(companyContractId: $companyContractId)
}
    `;
export type ChangeContractMutationFn = Apollo.MutationFunction<ChangeContractMutation, ChangeContractMutationVariables>;

/**
 * __useChangeContractMutation__
 *
 * To run a mutation, you first call `useChangeContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeContractMutation, { data, loading, error }] = useChangeContractMutation({
 *   variables: {
 *      companyContractId: // value for 'companyContractId'
 *   },
 * });
 */
export function useChangeContractMutation(baseOptions?: Apollo.MutationHookOptions<ChangeContractMutation, ChangeContractMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeContractMutation, ChangeContractMutationVariables>(ChangeContractDocument, options);
      }
export type ChangeContractMutationHookResult = ReturnType<typeof useChangeContractMutation>;
export type ChangeContractMutationResult = Apollo.MutationResult<ChangeContractMutation>;
export type ChangeContractMutationOptions = Apollo.BaseMutationOptions<ChangeContractMutation, ChangeContractMutationVariables>;
export const ChangeFaxDocument = gql`
    mutation changeFax($faxId: BigInteger!) {
  changeFax(faxId: $faxId)
}
    `;
export type ChangeFaxMutationFn = Apollo.MutationFunction<ChangeFaxMutation, ChangeFaxMutationVariables>;

/**
 * __useChangeFaxMutation__
 *
 * To run a mutation, you first call `useChangeFaxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeFaxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeFaxMutation, { data, loading, error }] = useChangeFaxMutation({
 *   variables: {
 *      faxId: // value for 'faxId'
 *   },
 * });
 */
export function useChangeFaxMutation(baseOptions?: Apollo.MutationHookOptions<ChangeFaxMutation, ChangeFaxMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeFaxMutation, ChangeFaxMutationVariables>(ChangeFaxDocument, options);
      }
export type ChangeFaxMutationHookResult = ReturnType<typeof useChangeFaxMutation>;
export type ChangeFaxMutationResult = Apollo.MutationResult<ChangeFaxMutation>;
export type ChangeFaxMutationOptions = Apollo.BaseMutationOptions<ChangeFaxMutation, ChangeFaxMutationVariables>;
export const GetMenusDocument = gql`
    query getMenus {
  menus(menuType: CONTENTS_LIBRARY) {
    name
    icon
    path
    externalLink
    externalLinkUrl
    menuType
    functionRoles
    authorized
    current
    enable
    editAuthority
    active
    url
    target
    hasChild
    childMenus {
      name
      icon
      path
      externalLink
      externalLinkUrl
      menuType
      functionRoles
      authorized
      current
      enable
      editAuthority
      active
      url
      target
      hasChild
      childMenus {
        name
        icon
        path
        externalLink
        externalLinkUrl
        menuType
        functionRoles
        authorized
        current
        enable
        editAuthority
        active
        url
        target
        hasChild
      }
    }
  }
}
    `;

/**
 * __useGetMenusQuery__
 *
 * To run a query within a React component, call `useGetMenusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMenusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMenusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMenusQuery(baseOptions?: Apollo.QueryHookOptions<GetMenusQuery, GetMenusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMenusQuery, GetMenusQueryVariables>(GetMenusDocument, options);
      }
export function useGetMenusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMenusQuery, GetMenusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMenusQuery, GetMenusQueryVariables>(GetMenusDocument, options);
        }
export type GetMenusQueryHookResult = ReturnType<typeof useGetMenusQuery>;
export type GetMenusLazyQueryHookResult = ReturnType<typeof useGetMenusLazyQuery>;
export type GetMenusQueryResult = Apollo.QueryResult<GetMenusQuery, GetMenusQueryVariables>;
export const GetUserDocument = gql`
    query getUser {
  user {
    userId
    displayName
    fullName
    photoUrl
    currentCompanyContract {
      companyContractId
      companyContractDisplayId
      cluezCompanyId
      companyPublish
      companyLogoUrl
      contractTradeNamePosition
      contractCompanyName
      contractCompanyPhoneticName
      contractCompanyNameFull
      contractDepartmentName
      contractDepartmentPhoneticName
      contractName
      contractEmail
      contractCountryCode
      contractCountryName
      contractZipcode
      contractSubdivisionCode
      contractSubdivisionName
      contractCityName
      contractAddress
      contractBuilding
      contractTel
      contractFax
      contractCompanyCategoryId
      contractEmployeesDescription
      contractSalesDescription
      userAuthorityLevel
      registrationAgencyFlag
      registrationAgencyAuthorityType
      useCatalogLimitedPublishFlag
      useApplicationPermissionType
    }
    currentAuthorities {
      roleName
      userAuthorityLevel
      userAvailabilityFlag
      userReferabilityFlag
      userEditabilityFlag
      userDataCreateFlag
      userDataModifyFlag
      userDataRemoveFlag
    }
    otherCompanyContracts {
      companyContractId
      companyContractDisplayId
      cluezCompanyId
      companyPublish
      companyLogoUrl
      contractTradeNamePosition
      contractCompanyName
      contractCompanyPhoneticName
      contractCompanyNameFull
      contractDepartmentName
      contractDepartmentPhoneticName
      contractName
      contractEmail
      contractCountryCode
      contractCountryName
      contractZipcode
      contractSubdivisionCode
      contractSubdivisionName
      contractCityName
      contractAddress
      contractBuilding
      contractTel
      contractFax
      contractCompanyCategoryId
      contractEmployeesDescription
      contractSalesDescription
      userAuthorityLevel
      registrationAgencyFlag
      registrationAgencyAuthorityType
      useCatalogLimitedPublishFlag
      useApplicationPermissionType
    }
    companyContractFaxes {
      id
      name
      fax
      selected
    }
    menus(menuType: CONTENTS_LIBRARY) {
      name
      icon
      path
      externalLink
      externalLinkUrl
      menuType
      functionRoles
      authorized
      current
      enable
      editAuthority
      active
      url
      target
      hasChild
      childMenus {
        name
        icon
        path
        externalLink
        externalLinkUrl
        menuType
        functionRoles
        authorized
        current
        enable
        editAuthority
        active
        url
        target
        hasChild
        childMenus {
          name
          icon
          path
          externalLink
          externalLinkUrl
          menuType
          functionRoles
          authorized
          current
          enable
          editAuthority
          active
          url
          target
          hasChild
        }
      }
    }
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    