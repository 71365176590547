import React, {
  useContext, useState, useEffect, createContext, VFC,
} from 'react';
import { AcUser, Menu, useGetUserQuery } from '../generated/graphql';
import { LoadingBox } from '../component/atoms/LoadingBox';
import { Ac500ErrorStandalone } from '../component/atoms/Ac500ErrorStandalone';

type AuthContextType = {
  currentUser?: AcUser;
  menu?: (Menu | undefined)[];
};

const AuthContext = createContext<AuthContextType>({
  currentUser: undefined,
  menu:        undefined,
});

export const useAuth = () => useContext(AuthContext);

export interface AuthProvideProps {
  children: React.ReactNode;
}

export const AuthProvider: VFC<AuthProvideProps> = (props) => {

  const [currentUser, setCurrentUser] = useState<AcUser | undefined>(undefined);
  const [menu, setMenu] = useState<(Menu | undefined)[] | undefined>(undefined);
  const { data, error, loading } = useGetUserQuery();

  useEffect(() => {

    setCurrentUser(data?.user);
    setMenu(data?.user?.menus);

  }, [data]);

  const value: AuthContextType = {
    currentUser,
    menu,
  };

  return (
    <AuthContext.Provider value={value}>
      {loading && <LoadingBox />}
      {!loading && error && <Ac500ErrorStandalone topPageUrl={'/'} />}
      {!loading && !error && props.children}
    </AuthContext.Provider>
  );

};
