import { VFC, useEffect, useRef } from 'react';
import style from './LoadingBox.module.scss';

export const LoadingBox: VFC = () => {

  const loadingBoxRef = useRef<null | HTMLDivElement>(null);
  const spinnerRef = useRef<null | HTMLSpanElement>(null);

  // after the component is mounted, set the size of the spinner icon based on the size of the spinner box
  useEffect(() => {

    if (loadingBoxRef.current && spinnerRef.current) {

      const loadingBoxHeight = loadingBoxRef.current.offsetHeight;
      spinnerRef.current.style.fontSize = `${loadingBoxHeight * 0.6}px`;

    }

  }, []);

  return (
    <div
      className={`${style.tableLoadingOverlay} d-flex position-absolute align-items-center justify-content-center`}
    >
      <div
        ref={loadingBoxRef}
        className={`${style.loadingBox} rounded d-flex shadow bg-white align-items-center justify-content-center`}
      >
        <i ref={spinnerRef} className={`fas fa-spin fa-spinner p-1 `} />
      </div>
    </div>
  );

};
