import { useEffect, useRef, useState } from 'react';

export const useShowMore = (maxHeightInPx: number) => {

  const contentRef = useRef<HTMLDivElement>(null);
  const [showAllContent, setShowAllContent] = useState(false);
  const [displayShowMoreLink, setDisplayShowMoreLink] = useState(false);

  useEffect(() => {

    if (maxHeightInPx && contentRef.current && contentRef.current.scrollHeight > maxHeightInPx) {

      setDisplayShowMoreLink(true);

    }

  }, [contentRef.current, maxHeightInPx]);

  return {
    contentRef,
    displayShowMoreLink,
    showAllContent,
    setShowAllContent,
  };

};
