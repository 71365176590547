import React from 'react';
import { TabLabel } from '../atoms/TabLabel';
import { TabType } from './Tabs';
import style from './Tab.module.scss';

export interface TabProps {
  active?: boolean;
  label: React.ReactNode | ((index: number) => React.ReactNode);
  index: number;
  setTabsState: React.Dispatch<React.SetStateAction<TabType[]>>;
  blink?: boolean;
  activeClassName?: string;
  notActiveClassName?: string;
  onClick?: () => void;
}

export const Tab = ({
  label,
  setTabsState,
  index,
  active,
  blink,
  activeClassName = style.active,
  notActiveClassName = style.notActive,
  onClick,
}: TabProps) => {

  const setActiveTab = () => {

    setTabsState((prevTabsState: TabType[]) => {

      const newTabsState = prevTabsState.map((tab) => ({ ...tab, active: false }));

      newTabsState[index].active = true;

      return newTabsState;

    });

    onClick?.();

  };

  return (
    <div
      onClick={setActiveTab}
      className={`${active ? activeClassName : notActiveClassName} flex-grow-1 ${blink ? style.blink : ''} ${
        style.tabWrapper
      }`}
    >
      <TabLabel index={index} label={label} />
    </div>
  );

};
