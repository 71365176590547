export const ROUTES: any = {
  HOME:     ``,
  ERROR404: ``,
  ERROR500: ``,
};

export const ROUTES_INDEX = Object.keys(ROUTES).reduce(
  (acc: any, key: string) => {
    const raw = ROUTES[key];
    const index = `${ROUTES[key]}/index.html`;

    const route = { [key]: [raw, index] };

    return { ...acc, ...route };
  },
  {},
);
