import { VFC } from 'react';
import smoothscroll from 'smoothscroll-polyfill';
// import style from './FooterScrollTop.module.scss';

smoothscroll.polyfill();

export const FooterScrollTop: VFC = () => (
  <div
    id="scrollTop"
    onClick={() =>
      window.scrollTo({
        top:      0,
        behavior: 'smooth',
      })}
  >
    <i className={`fa fa-angle-up inline left`} />
    このページのトップへ
  </div>
);
