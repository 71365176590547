import {
  VFC, useEffect, useRef, useState, useMemo,
} from 'react';
import { SideMenuSupport } from '../molecules/SideMenuSupport';
import { SideMenuList } from '../molecules/SideMenuList';
import { SideMenuCollapseButton } from '../atoms/SideMenuCollapseButton';
import style from './SideMenu.module.scss';

export interface SideMenuProps {
  setSidebarCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  sidebarCollapsed: boolean;
}

export const SideMenu: VFC<SideMenuProps> = (props) => {

  const { setSidebarCollapsed, sidebarCollapsed } = props;

  const [sideMenuHeight, setSideMenuHeight] = useState<number>(0);

  const sideMenuRef = useRef<HTMLDivElement>(null);

  const top = Math.max(sideMenuHeight + 70 - window.innerHeight, 0);

  const topString = `-${top}px`;

  const resizeObserver = useMemo(
    () =>
      new ResizeObserver((entries) => {

        setSideMenuHeight(sideMenuRef?.current?.offsetHeight || 0);

      }),
    [],
  );

  useEffect(() => {

    if (sideMenuRef.current) {

      setSideMenuHeight(sideMenuRef?.current?.offsetHeight || 0);

      resizeObserver.observe(sideMenuRef.current);

    }
    return () => {

      resizeObserver.disconnect();

    };

  }, []);

  return (
    <nav style={{ top: topString }} className={` ams-leftnav ${style.leftNavOverride} ${style.stickyScroll}`}>
      <SideMenuCollapseButton setSidebarCollapsed={setSidebarCollapsed} sidebarCollapsed={sidebarCollapsed} />
      <div ref={sideMenuRef} className={` m_leftNav`}>
        <div className={` inner-wrapper-sticky `}>
          <div className={` navInner `}>
            <SideMenuList sidebarCollapsed={sidebarCollapsed} />
            <SideMenuSupport />
          </div>
        </div>
      </div>
    </nav>
  );

};
