export interface TabLabelProps {
  index: number;
  label: React.ReactNode | ((index: number) => React.ReactNode);
}

/**
 * Tab label
 */
export const TabLabel = ({ index, label }: TabLabelProps) => {

  let _label;

  if (typeof label === 'function') {

    _label = label(index);

  } else {

    _label = label;

  }

  return <div className={`d-flex flex-column align-items-center py-2`}>{_label}</div>;

};
