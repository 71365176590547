import { VFC, useRef, useEffect } from 'react';
import style from './Search.module.scss';

export interface SearchProps {
  placeholder?: string;
  onSubmit: (inputValue?: string) => void;
  searchKeyword?: string;
}

export const Search: VFC<SearchProps> = (props) => {

  const { placeholder = '検索', onSubmit, searchKeyword = '' } = props;

  const inputRef = useRef<HTMLInputElement>(null);

  const onSubmitWrapper = (e: React.FormEvent<HTMLFormElement>) => {

    e.preventDefault();

    inputRef.current!.value = inputRef.current!.value.trim();
    const inputValue = inputRef.current!.value;

    // validation
    if (inputValue.length < 1) {

      return;

    }

    onSubmit(inputValue);

  };

  const clearInput = () => {

    inputRef.current!.value = '';
    inputRef.current!.focus();

  };

  useEffect(() => {

    inputRef.current!.value = searchKeyword;

  }, [searchKeyword]);

  const clearButton = (
    <i
      role="button"
      className={`far fa-times text-secondary fa-fw ${style.clearButton}`}
      onClick={clearInput}
      tabIndex={0}
      aria-label="clear input"
      data-testid="clear"
    />
  );

  return (
    <form data-testid="search" className={`d-flex ${style.search} `} onSubmit={onSubmitWrapper} noValidate>
      <div className={`d-flex position-relative flex-grow-1`}>
        <input
          required
          ref={inputRef}
          data-testid="input"
          placeholder={placeholder}
          className={` ${style.input} `}
        />
        {clearButton}
      </div>
      <button type="submit" data-testid="searchButton" className={` ${style.searchButton} `}>
        <i className={`fal fa-search ${style.searchIcon}`} />
      </button>
    </form>
  );

};
