import React from 'react';
import { Tab } from './Tab';
import style from './Tabs.module.scss';

export type TabType = {
  label: React.ReactNode | ((index: number) => React.ReactNode);
  active?: boolean;
  blink?: boolean;
  id: string;
  onClick?: () => void;
};

export interface TabsProps {
  tabsState: TabType[];
  setTabsState: React.Dispatch<React.SetStateAction<TabType[]>>;
  activeClassName?: string;
  notActiveClassName?: string;
}

export const setTabField = (
  id: string,
  fieldName: string,
  value: string | boolean,
  setTabsState: React.Dispatch<React.SetStateAction<TabType[]>>,
) => {

  setTabsState((prevTabsState: TabType[]) => {

    const newTabsState = prevTabsState.map((tab) => ({ ...tab })); // create deep copy
    const tabIndex = newTabsState.findIndex((tab) => tab.id === id);

    if (tabIndex < 0) {

      return newTabsState;

    }
    newTabsState[tabIndex][fieldName] = value;
    return newTabsState;

  });

};

export const Tabs = ({
  tabsState, setTabsState, activeClassName, notActiveClassName,
}: TabsProps) => {

  const tabComponents = tabsState.map((tabState, index) => (
    <Tab
      {...tabState}
      index={index}
      key={tabState.id}
      setTabsState={setTabsState}
      activeClassName={activeClassName}
      notActiveClassName={notActiveClassName}
    />
  ));

  return <div className={`${style.tabs} d-flex pointer`}>{tabComponents}</div>;

};
